<script>
import { ref } from 'vue'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms/use-cms'
import FaqContent from '/~/views/faq/faq.vue'

export default {
  name: 'faq-section',
  components: {
    FaqContent,
    BaseIcon,
    BaseCollapse,
  },
  setup() {
    const faqVisible = ref(false)
    const { faqQuestions } = useCms()

    return {
      faqVisible,
      faqQuestions,
    }
  },
}
</script>

<template>
  <div v-if="faqQuestions.length" class="p-5 pt-10 sm:p-[30px] sm:pt-12">
    <h3 class="mb-[30px] text-center sm:mb-5">Frequently Asked Questions</h3>

    <base-collapse v-model="faqVisible" label="Frequently Asked Questions">
      <template #trigger>
        <span
          class="mx-auto mb-[30px] flex h-12 w-12 items-center justify-center rounded-full border border-solid"
        >
          <base-icon
            :class="[faqVisible && 'rotate-180 transform']"
            size="sm"
            svg="plain/chevrondown"
          />
        </span>
      </template>

      <faq-content />
    </base-collapse>
  </div>
</template>
